<template>
  <v-container fill-height justify-content-start :class="{'pa-2': xsOnly}">
    <v-layout column class="pa-4">
      <!-- TITLE -->
      <v-layout wrap align-center class="flex-none">
        <v-btn :class="{'ma-1': xsOnly}" :fab="mdAndUp" icon text :disabled="!guests.read" @click="$router.push({ name: routeNames.ticket.name, params: { id: guests.read.bookingId } })"><v-icon color="grey darken-2" :large="mdAndUp">mdi-arrow-left</v-icon></v-btn>
        <h2 class="raleway grey--text text--darken-2 mt-3 mb-4" :class="{ 'display-2': mdAndUp, 'display-1': smAndDown }">
          {{ $t('guests.info_title') }}
        </h2>
      </v-layout>
      <v-layout v-if="loadingData" align-center justify-center>
        <v-progress-circular indeterminate color=primary />
      </v-layout>
      <v-layout v-else column class="pb-10 flex-none">
        <!-- GUEST INFO -->
        <v-layout justify-space-between align-center wrap class="flex-none">
          <v-layout wrap align-center class="my-1">
            <span class="grey--text text--darken-2 mr-2 headline">{{ getGuestFullName(guests.read) }}</span>
            <v-chip v-if="booking" class="subtitle-1 my-1 mr-2" label>
              <v-icon class="mr-2">{{ isParkBooking(booking) ? 'mdi-ticket' : 'mdi-bed' }}</v-icon>
              <span>{{ isParkBooking(booking) ? $t('bookings.park_visitor') : $t('bookings.hotel_guest') }}</span>
            </v-chip>
            <v-chip v-if="pricingGroupName" label class="subtitle-1 mr-2 my-1">{{ pricingGroupName }}</v-chip>
            <v-chip v-if="getGuestType(guests.read.typeId)" label class="subtitle-1 mr-2 my-1">
              <v-icon v-if="isGuestChild(guests.read)" class="mr-2">mdi-baby-face-outline</v-icon>
              <span>{{ $t(`guests.type.${getGuestType(guests.read.typeId)}`) }}</span>
            </v-chip>
          </v-layout>
          <v-layout align-center justify-end>
            <template v-if="findWristbandExternalId(guests.read) && booking">
              <!-- UNASSIGN TAG / TAG ID -->
              <tooltip-button
                :disabled="disconnectedPeripheralService || !isParkBooking(booking) || !selectedReader"
                :disabledTooltip="!(disconnectedPeripheralService || !isParkBooking(booking) || !selectedReader)"
                :tooltipText="disconnectedPeripheralService
                  ? $t('peripherals.warnings.disconnected_peripheral_service')
                  : !selectedReader
                    ? $t('peripherals.warnings.reader_not_configured')
                    : $t('guests.non_unassignable_wristband_hotel_guest')"
                :text="$t('guests.unassign_wirstband')"
                @click="triggerOpenUnassignProcess = new Date()"
                :loading="loadingUnassign"
                buttonClass="my-1 ml-2"
                color="primary"
                outlined
              />
              <v-chip label color="primary" class="ml-2">
                {{ `${$t('fields.id')} ${findWristbandExternalId(guests.read).id}` }}
              </v-chip>
            </template>
            <!-- ASSIGN TAG -->
            <tooltip-button v-else-if="booking"
              :disabled="!isParkBooking(booking) || isGuestDisabled(guests.read) || disconnectedPeripheralService || !selectedReader"
              :disabledTooltip="!(!isParkBooking(booking) || isGuestDisabled(guests.read) || disconnectedPeripheralService || !selectedReader)"
              :text="$t('guests.assign_wristband')"
              :tooltipText="isGuestDisabled(guests.read)
                ? $t('guests.visitor_disabled')
                : disconnectedPeripheralService
                  ? $t('peripherals.warnings.disconnected_peripheral_service')
                  :  !selectedReader
                    ? $t('peripherals.warnings.reader_not_configured')
                    : $t('guests.non_assignable_wristband_hotel_guest')"
              @click="assignTag"
              color="primary"
              buttonClass="my-1 ml-2"
              outlined
            />
            <!-- ASSIGN TAG -->
            <v-chip v-if="isGuestDisabled(guests.read)" color="red lighten-3 my-1 ml-2" class="subtitle-1" label>{{ $t('guests.disabled') }}</v-chip>
          </v-layout>
        </v-layout>
        <!-- BOOKING INFO -->
        <booking-date v-if="booking" :booking="booking" />
        <!-- BALANCE -->
        <guest-balance :accountCurrencies="accountCurrencies" />
        <!-- ORDER LIST -->
        <guest-orders
          @change="refreshData()"
          class="mt-2"
        />
        <!-- BALANCE ADJUSTMENT LIST -->
        <guest-balance-adjustments
          v-if="guests.read"
          :guest="guests.read"
          class="mt-5"
        />
        <!-- TRANSACTION LIST -->
        <guest-transactions
          v-if="guests.read"
          @refund="refreshData()"
          :guest="guests.read"
          class="mt-5"
        />
      </v-layout>
    </v-layout>
    <confirm-modal
      :triggerOpen="triggerOpenUnassignProcess"
      :text="$t('guests.confirm.unassign_wirstband')"
      :title="$t('default_content.warning')"
      @accept="unassignWirstBand"
    />
    <assign-reader v-if="state.assignReader.open" @update="refreshData" />
  </v-container>
</template>

<script>
import vuetifyMixins from '@/mixins/Vuetify'
import { getGuestFullName, isGuestDisabled, findWristbandExternalId } from '@/utils/GuestUtils.js'
import { mapState, mapGetters } from 'vuex'
import { GuestRequests } from '@/services/guest.requests'
import peripheralConnectionMixins from '@/mixins/PeripheralSocket'
import routeNames from '@/router/routes'
import dayjs from 'dayjs'

const guestService = new GuestRequests()

export default {
  data () {
    return {
      getGuestFullName,
      isGuestDisabled,
      loadingUnassign: false,
      loadingData: true,
      triggerOpenUnassignProcess: null,
      dayjs,
      routeNames,
      findWristbandExternalId
    }
  },
  computed: {
    ...mapState(['guests', 'state']),
    ...mapState({
      disconnectedPeripheralService: state => state.peripherals.disconnectedPeripheralService,
      selectedReader: state => state.peripherals.selectedReader
    }),
    ...mapGetters({
      pricingGroupTypeGetter: 'configuration/pricingGroupType',
      isGuestChild: 'configuration/isGuestChild',
      getGuestType: 'configuration/getGuestType',
      isParkBooking: 'configuration/isParkBooking',
      manualPaymentMethodId: 'configuration/manualPaymentMethodId',
      isCurrencyLegal: 'configuration/isCurrencyLegal',
      getPackage: 'packages/getPackage',
      guestPricingGroup: 'guests/guestPricingGroup',
      printerShift: 'shifts/printerShift'
    }),
    booking () {
      return this.guests.read ? this.guests.read.booking : null
    },
    accountCurrencies () {
      return this.guests.read && this.guests.read.extendedAccount ? this.guests.read.extendedAccount.currencies : []
    },
    pricingGroupName () {
      if (this.guestPricingGroup) {
        const pricingGroupType = this.pricingGroupTypeGetter(this.guestPricingGroup)
        return pricingGroupType
          ? pricingGroupType.charAt(0) + pricingGroupType.slice(1).toLowerCase().replace('_', ' ')
          : ''
      }
    }
  },
  methods: {
    afterUnassign (data) {
      if (data.success || data.error_code === 'NO_KEY_CANCELLED') {
        guestService.unassignWirstBand(this.guests.read.id)
          .then(() => {
            this.refreshData()
            this.$store.commit('state/setMessage', { text: this.$t('reader.success.key_cancelled'), color: 'success' })
          }).catch(() => {
            this.$store.commit('state/setMessage', { text: this.$t('guests.errors.unassign_wirstband'), color: 'error' })
          }).finally(() => {
            this.loadingUnassign = false
          })
      } else {
        this.$store.commit('state/setReaderError', data.error_code)
      }
      this.loadingUnassign = false
    },
    unassignWirstBand () {
      this.loadingUnassign = true
      this.sendAction('CANCEL_GUEST_KEY', { guest_id: this.guests.read.id }, this.afterUnassign, (error) => {
        this.$store.commit('state/setMessage', { text: this.$t('guests.errors.unassign_wirstband'), color: 'error' })
        this.loadingUnassign = false
      })
    },
    refreshData () {
      this.loadingData = true
      this.$store.dispatch('guests/get', { id: this.$route.params.id, save: true, decorate: true }).finally(() => {
        this.loadingData = false
      })
    },
    assignTag () {
      this.$store.commit('state/setAssignReaderState', [this.guests.read])
    }
  },
  created () {
    this.refreshData()
    this.$store.dispatch('packages/list')
    this.$store.dispatch('paymentMethods/list')
  },
  watch: {
    '$route.params.id' () {
      this.refreshData()
    }
  },
  components: {
    ConfirmModal: () => import('@/components/app-components/modals/ConfirmModal.vue'),
    LoadingField: () => import('@/components/app-components/LoadingField.vue'),
    BookingDate: () => import('@/components/view-components/bookings/BookingDate.vue'),
    GuestOrders: () => import('@/components/view-components/guests/GuestOrders.vue'),
    GuestBalance: () => import('@/components/view-components/guests/GuestBalance.vue'),
    GuestBalanceAdjustments: () => import('@/components/view-components/guests/GuestBalanceAdjustments.vue'),
    GuestTransactions: () => import('@/components/view-components/guests/GuestTransactions.vue'),
    TooltipButton: () => import('@/components/app-components/buttons/TooltipButton.vue'),
    AssignReader: () => import('@/components/app-components/modals/AssignTagOverlay.vue')
  },
  mixins: [
    vuetifyMixins,
    peripheralConnectionMixins
  ],
  beforeDestroy () {
    this.$store.commit('guests/setRead', null)
  },
  name: 'Guest'
}
</script>
